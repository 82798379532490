angular
  .module('app')
  .component('animalitos', {
    template: '<div ng-include="$ctrl.urlTemplate"></div>',
    controller: AnimalitosController,
    bindings: {
      drawsJungla: '<',
      drawsLottoActivo: '<',
      drawsLaGranjita: '<',
      drawsZoologicoActivo: '<',
      drawsGranjaMillonaria: '<',
      drawsGranjazo: '<',
    }
  });

AnimalitosController.$inject = ['toastr', 'moment', 'animalitoService', 'user', '$uibModal', '$rootScope', '$sce', '$timeout', '$interval', 'cashierService', '$window', 'miscelaneos', '$stateParams', 'cashierService', '$state'];

function AnimalitosController(toastr, moment, $animalitoService, $user, $uibModal, $rootScope, $sce, $timeout, $interval, $cashierService, $window, $miscelaneos, $stateParams, cashierService, $state) {
  var vm = this;
  vm.color = '#00002A';
  vm.products = _.where($miscelaneos.getAndUncompressDraws(), { tipo: 'animalitos' });
  vm.taquillaSelected = _.findWhere(vm.products, { orden: 1 }).slug_name;
  vm.countDraw;
  vm.amount = undefined;
  vm.interval;
  vm.plays = [];
  vm.sale = sale;
  vm.step1 = true;
  vm.step2 = false;
  vm.step3 = false;
  vm.showAnimals = true;
  vm.drawsWithPlays = [];
  vm.allDrawsSelected = []
  vm.addPlays = addPlays;
  vm.nextStep = nextStep;
  vm.pagoMovil = undefined;
  vm.clearPlays = clearPlays;
  vm.cancelPlay = cancelPlay;
  vm.aceptPlays = aceptPlays;
  vm.deleteDraw = deleteDraw;
  vm.showTicket = showTicket;
  vm.selectDraw = selectDraw;
  vm.deleteAnimal = deleteAnimal;
  vm.checkAnimalitos = checkAnimalitos;
  vm.clearAnimalitos = clearAnimalitos;
  vm.checkDrawSelected = checkDrawSelected;
  vm.selectDrawByProduct = selectDrawByProduct;
  vm.animalitos = $animalitoService.getAnimalsV2('jungla');
  vm.producto_id = $miscelaneos.getProductIdByGame('Jungla');
  vm.animalitosJungla = $animalitoService.getAnimalsV2('jungla');
  vm.animalitosGranjita = $animalitoService.getAnimalsV2('granjita');
  vm.animalitosLottoActivo = $animalitoService.getAnimalsV2('lotto-activo');
  vm.animalitosZoologico = $animalitoService.getAnimalsV4('zoologico-active-extend');
  vm.animalitosGranjaMillonaria = $animalitoService.getAnimalsV1('granja-millonaria');
  vm.animalitosElGranjazo = $animalitoService.getAnimalsV3('el-granjazo');
  vm.date = moment().format('DD/MM/YY');
  var day = moment().format('d');
  var hour = moment().format('H:mm');


  vm.$onInit = function () {
    vm.loading = true;
    vm.currentUser = $user.getCurrentUser();
    vm.loteria_id = vm.drawsJungla.length > 0 ? vm.drawsJungla[0].loteria_id : null;
    if ($stateParams.cashier_id == '') {
      getAllCashiers(vm.loteria_id)
    } else {
      var current_config = _.findWhere(vm.currentUser.super_juegos.conf_lot, { moneda_id: vm.currentUser.cajero.moneda_id })
      vm.config_lot = {
        mpj: current_config.mpj,
        jpt: current_config.jpt,
        mmt: current_config.mmt,
        mt: current_config.mt,
        currency: current_config.currency
      };
      if (vm.currentUser.cajero.currency == 'Bs' && vm.currentUser.pay_pago_movil) {
        showModalPagoMovil();
      }
    }
    if (window.mobileAndTabletcheck()) {
      vm.urlTemplate = 'app/components/animalitos/animalitos-mobile.html';
    } else {
      vm.urlTemplate = 'app/components/animalitos/animalitos.html';
    }
    console.log(vm.drawsGranjazo, 'drawsGranjazo', vm.animalitosElGranjazo)
  }

  vm.showKeyboard = false;  // Controla si el teclado está visible o no
  vm.keyboardStyle = {};  // Estilo dinámico para el teclado flotante

  // Función para mostrar el teclado y posicionarlo
  vm.showKeyboardAtPosition = function(event) {
    // Obtener el elemento input
    if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
      const inputElement = event.target;
      const rect = inputElement.getBoundingClientRect();
  
      // Calcular la posición del teclado debajo del input
      vm.keyboardStyle = {
        position: 'absolute',
        top: (rect.bottom + window.scrollY) + 'px',  // Justo debajo del input
        left: (rect.left + window.scrollX) + 'px',  // Alinear con el borde izquierdo del input
        zIndex: 1000  // Asegurarse de que esté en frente de otros elementos
      };
  
      vm.showKeyboard = true;  // Mostrar el teclado
    }
  };

  // Función para manejar la escritura de teclas
  vm.handleKeyPress = function(key) {
    if (key !== 'close' && key !== 'addPlays') {
      vm.inputValue += key;
    }else if (key === 'close') {
      vm.handleClose();
    }else if(key === 'addPlays'){
      vm.handleClose();
      addPlays();
    }
  };

  // Función para manejar el cierre del teclado
  vm.handleClose = function() {
    vm.showKeyboard = false;  // Ocultar el teclado cuando se haga clic en cerrar
  };

  function getAllCashiers(loteria_id) {
    cashierService.getAllCashiers(loteria_id)
      .then(function (res) {
        vm.allCashiers = res;
        vm.loading = false;
        showWallets(res);
      })
  }

  function showWallets(allCashiers) {
    var modal = $uibModal.open({
      animation: true,
      component: 'modalAllCashiers',
      windowClass: 'modalAllCashiers',
      backdrop: 'static',
      keyboard: false,
      size: 'xs',
      resolve: {
        allCashiers: function () {
          return allCashiers;
        }
      }
    })
    modal.result.then(function (res) {
      var oldCashier = vm.currentUser.cajero;
      var newFavorite = _.findWhere(res, { checked: true });
      if (newFavorite) {
        vm.loadingCajeroWeb = true;
        vm.currentUser.cajero = newFavorite;
        $user.setCurrentUser(vm.currentUser);
        $rootScope.$broadcast('updatedUser', vm.currentUser);
        $state.go("animalitos", { "cashier_id": vm.currentUser.cajero.id });
      }
    })
  }

  function showModalPagoMovil() {
    var modal = $uibModal.open({
      animation: true,
      component: 'modalPagoMovil',
      windowClass: 'modalPagoMovil',
      backdrop: 'static',
      keyboard: false,
      size: 'xs',
    })
    modal.result.then(function (res) {
      vm.pagoMovil = res;
    })
  }

  function addPlays() {
    vm.showKeyboard = false;
    var currentAnimalitos;
    var sorteos;
    switch (vm.taquillaSelected) {
      case 'jungla_millonaria':
        currentAnimalitos = angular.copy(vm.animalitosJungla)
        sorteos = angular.copy(vm.drawsJungla)
        break;
      case 'la_granjita':
        currentAnimalitos = angular.copy(vm.animalitosGranjita)
        sorteos = angular.copy(vm.drawsLaGranjita)
        break;
      case 'zoologico_activo':
        currentAnimalitos = angular.copy(vm.animalitosZoologico)
        sorteos = angular.copy(vm.drawsZoologicoActivo)
        break;
      case 'granja_millonaria':
        currentAnimalitos = angular.copy(vm.animalitosGranjaMillonaria)
        sorteos = angular.copy(vm.drawsGranjaMillonaria)
        break;
      case 'granjazo':
        currentAnimalitos = angular.copy(vm.animalitosElGranjazo)
        sorteos = angular.copy(vm.drawsGranjazo)
        break;
      case 'lotto_activo':
        currentAnimalitos = angular.copy(vm.animalitosLottoActivo)
        sorteos = angular.copy(vm.drawsLottoActivo)
        break;
      default:
        break;
    }

    //revisar el length de drawsWithPlays para no sustituir
    if (validateAmountMult()) {
      if (vm.checkDrawSelected(sorteos)) {
        if (vm.checkAnimalitos(currentAnimalitos)) {
          _.forEach(sorteos, function (draw) {
            if (draw.checked) {
              var findDraw = _.findWhere(vm.drawsWithPlays, { c: draw.id });
              if (findDraw != undefined) {
                _.forEach(currentAnimalitos, function (ani) {
                  if (ani.selected) {
                    var anim = _.findWhere(vm.drawsWithPlays, { n: ani.number, c: draw.id });
                    if (anim) {
                      anim.m = parseFloat(anim.m) + parseFloat(vm.amount);
                    } else {
                      vm.drawsWithPlays.push({
                        i: null,
                        n: ani.number,
                        m: angular.copy(vm.amount),
                        c: draw.id,
                        animalito: ani.name,
                        nombre_corto: draw.nombre
                      })
                    }
                  }
                })
                // vm.clearAnimalitos();
              } else {
                if (vm.drawsWithPlays.length > 0) {
                  _.forEach(currentAnimalitos, function (ani) {
                    if (ani.selected) {
                      vm.drawsWithPlays.push({
                        i: null,
                        n: ani.number,
                        m: angular.copy(vm.amount),
                        c: draw.id,
                        animalito: ani.name,
                        nombre_corto: draw.nombre
                      })
                    }
                  })
                } else {
                  var plays = getAnimalitos(currentAnimalitos, draw);
                  vm.drawsWithPlays = plays;
                }
              }
            }
          });
          if (window.mobileAndTabletcheck()) {
            nextStep();
          }
          vm.clearAnimalitos();
        } else {
          toastr.warning('Seleccione un animalito');
        }
      } else {
        toastr.warning('Seleccione un sorteo.', 'Por favor espere!');
      }

      calculateTotal();
    }
  }

  function getAnimalitos(animalitos, draw) {
    var animalPlay = _.reduce(animalitos, function (memo, animalito) {
      if (animalito.selected) {
        memo.push({
          i: null,
          n: animalito.number,
          m: angular.copy(vm.amount),
          c: draw.id,
          animalito: animalito.name,
          nombre_corto: draw.nombre
        })
      }
      return memo;
    }, []);
    return animalPlay;
  }


  function sale() {
    if (parseInt(vm.config_lot.mmt) > parseInt(vm.total.monto)) {
      $window.swal('Aviso', "Monto minimo del ticket: " + vm.prettyAmount(vm.config_lot.mmt), 'error');
      return
    }
    var i = 1;

    vm.sendData = true;

    _.forEach(vm.drawsWithPlays, function (play) {
      play.i = i;
      i++;
    });

    var data = {
      fec: moment().format('DD/MM/YY'),
      ced: vm.currentUser.rif,
      nom: vm.currentUser.nombre,
      ts: 1,
      correo: vm.currentUser.correo,
      jug: vm.drawsWithPlays,
      compress: false,
      ncanje: 0,
      mcanje: 0,
      cupon: "",
      usa_cupon: false,
      app: 1,
      jp: "H",
      ani: true,
      tip: "N",
      uti: 0,
      cod: vm.currentUser.id,
      ven: 1,
      ani_tipo: 0,
      producto_id: 'animalitos',
      beneficiencia: 'LOTERIA DEL ZULIA',
      cda: true,
      cajero_id: vm.currentUser.cajero.id,
      cedula: vm.pagoMovil != undefined ? vm.pagoMovil.cedula : '',
      telefono: vm.pagoMovil != undefined ? vm.pagoMovil.telefono : '',
      banco_id: vm.pagoMovil != undefined ? vm.pagoMovil.banco : '',
    }


    if (vm.cupon != null && vm.total.monto > vm.cupon.disponible) {
      $window.swal({
        html: 'Modifique la juagada si desea usar el bono exacto. <br>' +
          '<strong>Monto disponible:</strong>' + vm.cupon.disponible + '<br>',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false,
      }).then(function (result) {
        if (result.value) {
          $animalitoService.sale(data)
            .then(function (res) {
              vm.sendData = false;
              completeSale(res);
            }, function (err) {
              vm.sendData = false;
              $window.swal('Aviso', err.data.message, 'warning');
            })
        } else {
          vm.sendData = false;
          return;
        }
      })
    } else {
      $animalitoService.sale(data)
        .then(function (res) {
          vm.sendData = false;
          completeSale(res);
        }, function (err) {
          vm.sendData = false;
          $window.swal('Aviso', err.data.message, 'warning');
        })
    }
  }

  function completeSale(res) {
    switch (res.cmd) {
      case "C11":
        var modal = $uibModal.open({
          animation: true,
          component: 'modalConfirmLoteries',
          backdrop: 'static',
          windowClass: 'modalConfirmLoteries',
          size: 'md',
          resolve: {
            plays: function (){
              return vm.drawsWithPlays;
            },
            playsDenied: function (){
              return res;
            },
            cajero_Web: function(){
              return vm.currentUser.cajero;
            }
          },
        });

        modal.result.then(function (res) {
          vm.aceptPlays(res);
          vm.clearPlays();
        }, function (err) {
          vm.cancelPlay(res);
          vm.clearPlays();
        })
        break;
      case "C12":
        toastr.error('Las Jugadas Seleccionadas no tienen ningun limite', 'Error');
        vm.clearAnimalitos();
        vm.clearPlays();
        break;
      case "C10":
        vm.clearPlays();
        vm.clearAnimalitos();
        $rootScope.$broadcast('updateCajeroUser', res.cajero_web);
        if (res.cupon != null) {
          $rootScope.$broadcast('updateCupon', res.cupon);
        }
        vm.ticket = $sce.trustAsHtml(angular.copy(res.ticket.replace(/\n|\r/g, '<br>')));
        vm.ticket_ws = angular.copy(res.ticket.replace(/\\n/g, '<br>'));
        vm.ticket_print = angular.copy(res.ticket.replace(/\n|\r/g, '\n'));
        $timeout(function () {
          vm.showTicket(vm.ticket, vm.ticket_print, vm.ticket_ws, res.codagen_info, res.nt)
        }, 0)
        vm.total = undefined;
        if (window.mobileAndTabletcheck()) {
          vm.step1 = true;
          vm.step3 = false;
        }
        break;
      default:

    }
  }

  function showTicket(ticket, ticket_print, ticket_ws, codagen_info, tracking_id) {
    var modal = $uibModal.open({
      animation: true,
      component: 'gModalTicket',
      backdrop: 'static',
      windowClass: 'modalTicket',
      size: 'xs',
      resolve: {
        ticket: function () {
          return ticket;
        },
        ticket_print: function () {
          return ticket_print;
        },
        ticket_ws: function () {
          return ticket_ws;
        },
        codagen_info: function(){
          return codagen_info;
        },
        tracking_id: function(){
          return tracking_id ;
        }
      },
    })

    modal.result.then(function (res) {
      $rootScope.$emit('updateCupon', null)
      if(vm.currentUser.modality == 'cashinCashout'){
        $rootScope.$emit('updateModality',{});
        vm.currentUser.modality = 'autoServicio';
        $user.setCurrentUser(vm.currentUser);
      }
      $state.go('lobby');
    }, function(err){
      $state.go('lobby');
    })
  }

  function selectDraw(data) {
    _.forEach(vm.drawsZoo, function (loteria) {
      if (loteria.checked) {
        var sorteo = _.findWhere(loteria.sorteos, { code_sorteo: data.code_sorteo })
        if (sorteo) {
          sorteo.checked = !sorteo.checked;
        }
      }
    })
  }

  function selectDrawByProduct(product) {
    _.forEach(vm.products, function (data) {
      if (data.checked) {
        data.checked = false;
      }
    })
    product.checked = true;
    vm.taquillaSelected = product.slug_name;
  }

  function aceptPlays(data) {
    vm.clearPlays();
    var data = {
      cod: vm.currentUser.id,
      ven: 1,
      ticket: data.nt,
      jp: "H",
      producto_id: 'animalitos',
      cajero_id: vm.currentUser.cajero.id
    }
    $animalitoService.confirmSaleZoo(data)
      .then(function (res) {
        completeSale(res);
        if (window.mobileAndTabletcheck()) {
          vm.step1 = true;
          vm.step3 = false;
        }
      })
  }

  function cancelPlay(data) {
    var data = {
      cod: vm.currentUser.id,
      ven: 1,
      ticket: data.nt,
      jp: "H"
    }
    $animalitoService.reverTicket(data)
      .then(function (res) {
        if (window.mobileAndTabletcheck()) {
          vm.step1 = true;
          vm.step3 = false;
        }
      })
  }



  function checkDrawSelected(draws) {

    var i = 0;
    _.forEach(draws, function (draw) {
      if (draw.checked) {
        i++
      };
    });

    return i > 0 ? true : false;
  }

  function deleteAnimal(draw, animal, index) {
    vm.drawsWithPlays.splice(index, 1);
    calculateTotal();
  }

  function deleteDraw(draw) {
    vm.drawsWithPlays.splice(draw, 1);
    calculateTotal();
  }

  function clearAnimalitos() {
    _.forEach(vm.draws, function (draw) {
      draw.checked = false;
    })
    _.forEach(vm.animalitos, function (animalito) {
      animalito.selected = false;
    })

    _.forEach(vm.drawsZoo, function (loteria) {
      loteria.checked = false;
      _.forEach(loteria.sorteos, function (sorteo) {
        if (sorteo.checked) {
          sorteo.checked = false;
        }
      })
    })
    vm.amount = undefined;
  }

  function checkAnimalitos(animalitos) {
    var i = 0;
    _.forEach(animalitos, function (animal) {
      if (animal.selected) {
        i++
      };
    });

    if (i > 0) { return true; };

    return false;
  }

  function clearPlays() {
    vm.drawsWithPlays = [];
    clearAnimalitos();
    calculateTotal();
    vm.drawsWithPlays = [];
    if (window.mobileAndTabletcheck()) {
      vm.step3 = false;
      $timeout(function () {
        vm.step1 = true;
      }, 0)
    }
  }
  function nextStep() {
    if (vm.step1) {
      vm.step1 = false;
      $timeout(function () {
        vm.step3 = true;
      }, 0)
      return
    }
  }

  vm.continuePlaying = function () {
    vm.amount = undefined;

    if (window.mobileAndTabletcheck()) {
      vm.step3 = false;
      setTimeout(function () {
        vm.step1 = true;
      })
    }
    _.forEach(vm.draws, function (data) {
      data.checked = false;
    })

    _.forEach(vm.drawsZoo, function (data) {
      data.checked = false;
    })

    _.forEach(vm.animalitos, function (data) {
      data.selected = false;
    })
  }


  function calculateTotal() {
    vm.total = _.reduce(vm.drawsWithPlays, function (memo, data) {
      memo['monto'] += parseFloat(data.m);
      return memo;
    }, { monto: 0, jugadas: 0 });
  }

  function checkCoupons() {
    $cashierService.getCoupon()
    .then(function (res) {
      if(res != null){
        if(res.coupon && res.cajero_web){
          vm.cupon = res.coupon;
          vm.cashier_id
          $rootScope.$broadcast('updateCupon', res.cupon);
        }
      }
    })
  }

  function checkDraws() {

    if (vm.draws.length > 0) {
      vm.interval = $interval(function () {
        var currentHora = parseInt(moment().format('Hmm'))
        _.forEach(vm.draws, function (data, index) {
          var horaSorteo = parseInt(data.horac.split(':').join(''))
          if (horaSorteo <= currentHora) {
            window.setTimeout(function (res) {
              vm.draws.splice(index, 1);
            }, 0)
          }
        })
      }, 1000);
    } else {
      vm.cancelInterval();
    }
  }

  vm.prettyAmount = function (amount) {
    return accounting.formatMoney(amount, '', ',', '.')
  }

  function validateAmountMult() {
    var isValid = parseInt(vm.amount) % vm.config_lot.mt;
    if (isValid == 0) {
      return true
    } else {
      $window.swal('Aviso', "La jugada debe ser multriplo de " + vm.prettyAmount(vm.config_lot.mt), 'error');
      return
    }
  }

  vm.cancelInterval = function () {
    $interval.cancel(vm.interval);
  }

  vm.$onDestroy = function () {
    vm.cancelInterval();
  }

  $rootScope.$emit('hideFooter', {})
  $rootScope.$emit('updateState', $state.current.name)
  // checkDraws();
}
