function ModalPrintCashinCashout($user) {
  var vm = this; // Asegúrate de que `vm` esté declarado aquí
  vm.printScreenShot = function () {
    var modalElement = document.getElementById('modalQR');

    html2canvas(modalElement, {
      scale: 2,
      useCORS: true,
    }).then(function (canvas) {
      var imgData = canvas.toDataURL('image/png');
      var printWindow = window.open('', '_blank', 'width=800,height=600');

      var htmlContent =
        '<html>' +
        '<head>' +
        '<title>Imprimir QR</title>' +
        '<style>' +
        '@media print {' +
        '@page { size: 80mm 100mm; margin: 0; }' +
        'body { display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0; }' +
        'img { max-width: 80mm; max-height: 100mm; display: block; }' +
        '}' +
        '</style>' +
        '</head>' +
        '<body>' +
        '<img src="' +
        imgData +
        '" alt="QR Code" />' +
        '</body>' +
        '</html>';

      printWindow.document.write(htmlContent);
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      };
    });
  };
}
