(function() {
  'use strict';
  angular
    .module('app')
    .factory('agentsPanelService', agentsPanelService);

  agentsPanelService.$inject = ['$http', 'api','$resource'];

  function agentsPanelService($http, api, $resource) {
    
    function hashToParams(params) {
      if(params){
        var params_url = '';
  
        Object.keys(params).forEach(function (item, index) {
          if (index == 0 && params[item] != null) {
            params_url += '?' + item + '=' + params[item];
          } else if (index != 0 && params[item] != null) {
            params_url += '&' + item + '=' + params[item];
          }
        });
        return params_url;
      }else{
        return null
      }

    }

    var estaciones = $resource(api.getApiSPJ('estaciones/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' }, query: { method: 'GET', params: { user_id: '@_tipo_estructura' }, isArray: true } });
    var taquillas = $resource(api.getApiSPJ('taquillas/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' }, query: { method: 'GET', params: { padre_id: '@_padre_id'}, isArray: true } });

    function getMyPointSales() {
      return $http.get(api.getApiSPJ('point_sales'))
      .then(function(res){
        return res.data;
      })
    }
    function getMyPointSale(id) {
      return $http.get(api.getApiSPJ('point_sales/'+id))
      .then(function(res){
        return res.data;
      })
    }

    
    function createPointSale(data) {
      return $http.post(api.getApiSPJ('point_sales'), data)
      .then(function(res){
        return res.data;
      })
    }
    function updatePointSale(data) {
      return $http.post(api.getApiSPJ('point_sales/update_point_sales'), data)
      .then(function(res){
        return res.data;
      })
    }


    

    function getMySuggested(){
      return $http.get(api.getApiSPJ('suggested'))
      .then(function(res){
        return res.data;
      })
    }

    function getMySubAgents() {
      return $http.get(api.getApiSPJ('sub_agentes'))
      .then(function(res){
        return res.data;
      })
    }
    function createSubAgents(data) {
      return $http.post(api.getApiSPJ('sub_agentes'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getMyPercentsBonus(){
      return $http.get(api.getApiSPJ('estructura/get_configuracion_estructura'))
      .then(function(res){
        return res.data;
      })
    }

    function getStructurePercentsBonus(id){
      return $http.get(api.getApiSPJ('estructura/get_configuracion_estructura_id?estructura_id='+id))
      .then(function(res){
        return res.data;
      })
    }
    function getReportCuadre(data){
      return $http.get(api.getApiSPJ('report'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }

    function getTicketsPrintView(data){
      return $http.get(api.getApiSPJ('print_view'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }

    function findTrackingOnPrintView(id, view){
      return $http.get(api.getApiSPJ('print_view/'+id+'?view='+view))
      .then(function(res){
        return res.data;
      })
    }

    function printTicket(data){
      return $http.post(api.getApiSPJ('print_view/print_ticket'), data)
      .then(function(res){
        return res.data;
      })
    }
    function borrarTicket(data){
      return $http.post(api.getApiSPJ('print_view/borrar_ticket'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getTicketPremiados(data) {
      return $http.post(api.getUrlVentaCentinela('ventas/get_premio'), data)
      .then(function(res){
        return res.data;
      })
    }

    function pagoPremio(data){
      return $http.post(api.getUrlVentaCentinela('ventas/pago_premio'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getTipoJuego(){
      return $http.get(api.getApiSPJ('miscelaneos/tipo_juego'))
      .then(function(res){
        return res.data;
      })
    }

    


    return {
      getMyPointSales: getMyPointSales,
      createPointSale: createPointSale,
      getMySuggested: getMySuggested,
      getMyPercentsBonus: getMyPercentsBonus,
      getMySubAgents: getMySubAgents,
      createSubAgents: createSubAgents,
      getMyPointSale: getMyPointSale,
      getStructurePercentsBonus: getStructurePercentsBonus,
      updatePointSale: updatePointSale,
      getReportCuadre: getReportCuadre,
      estaciones: estaciones,
      getTicketPremiados: getTicketPremiados,
      pagoPremio: pagoPremio,
      taquillas: taquillas,
      getTicketsPrintView: getTicketsPrintView,
      printTicket: printTicket,
      borrarTicket: borrarTicket,
      getTipoJuego: getTipoJuego,
      findTrackingOnPrintView: findTrackingOnPrintView
    };

    

  }
})();
