angular
  .module('app')
  .component('modalActiveCashinCashout', {
    templateUrl: 'app/components/lobby/modalCashinCashout.html',
    controller: ModalActiveCashinCashoutController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

ModalActiveCashinCashoutController.$inject = ['$state', 'user', 'BASE_URL', '$timeout', '$scope','miscelaneos'];

function ModalActiveCashinCashoutController($state, $user, BASE_URL, $timeout, $scope, $miscelaneos) {
  var vm = this;
  vm.cancel = cancel;
  vm.setCashier = setCashier;
  vm.currentUser = $user.getCurrentUser();
  vm.uuid = ''; // Añadido para el campo UUID

  vm.$onInit = function() {
    vm.loading = false;
    vm.baseUrl = BASE_URL;
    vm.tabPanel = 'first';
    vm.currentState = $state.current.name;
    $miscelaneos.getBancos()
    .then(function(res){
      vm.banks = res;
    })
    vm.allCashiers = _.map(vm.resolve.allCashiers, function(data) {
      data.checked = false;
      return data;
    });

    // Observar el valor del UUID y ejecutar automáticamente el método si es válido
    $scope.$watch(function() {
      return vm.uuid;
    }, function(newVal) {
      if (newVal && validateUuid(newVal)) {
        vm.saveForm(); // Llamar automáticamente a la función de guardar
      }
    });
    
  };

  vm.showKeyboard = false;  // Controla si el teclado está visible o no
  vm.keyboardStyle = {};  // Estilo dinámico para el teclado flotante

  // Función para mostrar el teclado y posicionarlo
  vm.showKeyboardAtPosition = function(event) {
    // Obtener el elemento input
    if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
      vm.keyboardStyle = {
        position: 'absolute',
        top: '10px',  // Alineado al top del modal
        right: '-260px',  // Alineado al borde derecho del modal
        zIndex: 1000  // Asegurarse de que esté por encima de otros elementos
      };
  
      // Usamos un pequeño timeout para asegurarnos de que los cambios de estilo se apliquen correctamente
      $timeout(function() {
        vm.showKeyboard = true;
      }, 0);
    }
  };

  // Función para manejar la escritura de teclas
  vm.handleKeyPress = function(key) {
    if (key !== 'close' && key !== 'addPlays') {
      vm.inputValue += key;
    }else if (key === 'close') {
      vm.handleClose();
    }else if(key === 'addPlays'){
      vm.handleClose();
      addPlays();
    }
  };

  // Función para validar el UUID
  function validateUuid(uuid) {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(uuid);
  }

  vm.save = function(){
    vm.loading = true;
    $timeout(function(){
      vm.loading = false;
      Swal('¡Éxito!', 'Saldo QR Comprado correctamente.', 'success');
      // vm.close({ $value: vm.allCashiers });
    }, 2000);
  }

  vm.saveForm = function() {
    vm.close({ $value: vm.uuid });
  };

  vm.handleClose = function() {
    vm.showKeyboard = false;
  };

  function setCashier(cashier) {
    _.forEach(vm.allCashiers, function(data) {
      data.checked = false;
    });
    cashier.checked = true;

    vm.close({ $value: vm.allCashiers });
  }

  function cancel() {
    vm.dismiss({ $value: 'cancel' });
  }

  vm.gotToCashier = function() {
    vm.dismiss({ $value: 'cancel' });
    $state.go('lobbyUser', { 'cashier_id': null });
  };

  vm.gotToLobby = function() {
    vm.dismiss({ $value: 'cancel' });
    $state.go('lobby');
  };
}
