(function() {
  'use strict';
  angular
    .module('app')
    //Variables de integracion
    .constant('HASH_CABALLOS', 'fbb9c413-f157-11ee-95dc-bc2411bfd8ae')
    .constant('_', _)
    .constant('accounting', accounting)
    .constant('swal', 'swal')
    .constant('moment', moment);
})();
  