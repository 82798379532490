angular
  .module('app')
  .component('loteries', {
    template: '<div ng-include="$ctrl.urlTemplate"></div>',
    controller: LoteriesController,
    bindings: {
      drawsLoteries: '<'
    }
  });

LoteriesController.$inject = ['toastr', 'moment', 'loteriesService', 'user', '$uibModal', '$rootScope', '$sce', '$timeout', '$state', 'cashierService', '$window', 'miscelaneos','$stateParams','hotkeys'];

function LoteriesController(toastr, moment, $loteriesService, $user, $uibModal, $rootScope, $sce, $timeout, $state, $cashierService, $window, $miscelaneos, $stateParams, hotkeys) {
  var vm = this;
  vm.color = '#00002A';
  vm.countDraw;
  vm.amount = undefined;
  vm.plays = [];
  vm.sale = sale;
  vm.step1 = true;
  vm.step2 = false;
  vm.step2 = false;
  vm.showAnimals = true;
  vm.listCombinationsToPlay = [];
  vm.addPlays = addPlays;
  vm.nextStep = nextStep;
  vm.clearDraws = clearDraws;
  vm.clearPlays = clearPlays;
  vm.cancelPlay = cancelPlay;
  vm.aceptPlays = aceptPlays;
  vm.deleteDraw = deleteDraw;
  vm.showTicket = showTicket;
  vm.deleteNumber = deleteNumber;
  vm.selectAllDraws = selectAllDraws;
  vm.getSerieNumbers = getSerieNumbers;
  vm.checkDrawSelected = checkDrawSelected;
  vm.getNumbersPermuted = getNumbersPermuted;
  vm.getCorridaNumbers = getCorridaNumbers;
  vm.pagoMovil = undefined;
  vm.producto_id = 5;
  vm.showKeyboard = false;

  vm.date = moment().format('DD/MM/YY');
  var day = moment().format('d');
  var hour = moment().format('H:mm');

  vm.formPlays = function(){
    if(checkDrawSelected()){
      var el = angular.element(document.querySelector('#amount'));
      if(el.length > 0){
        el[0].focus();
      }
    }
  }

  vm.$onInit = function () {
    vm.signs = $miscelaneos.getSigns();
    vm.currentUser = $user.getCurrentUser();
    if (window.mobileAndTabletcheck()) {
      vm.urlTemplate = 'app/components/loteries/loteries-mobile.html';
    } else {
      vm.urlTemplate = 'app/components/loteries/loteries-new.html';
    }
    if($stateParams.cashier_id == ''){
      getAllCashiers(vm.loteria_id)
    }else{
      var current_config = _.findWhere(vm.currentUser.super_juegos.conf_lot, {moneda_id: vm.currentUser.cajero.moneda_id})
      vm.config_lot = {
        mpj: current_config.mpj,
        jpt: current_config.jpt,
        mmt: current_config.mmt,
        mt: current_config.mt,
        currency: current_config.currency
      };
      if(vm.currentUser.pay_pago_movil){
        showModalPagoMovil();
      }
    }
  }

  vm.showKeyboard = false;  // Controla si el teclado está visible o no
  vm.keyboardStyle = {};  // Estilo dinámico para el teclado flotante

  // Función para mostrar el teclado y posicionarlo
  vm.showKeyboardAtPosition = function(event) {
    // Obtener el elemento input
    if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
      const inputElement = event.target;
      const rect = inputElement.getBoundingClientRect();
  
      // Calcular la posición del teclado debajo del input
      vm.keyboardStyle = {
        position: 'absolute',
        top: (rect.bottom + window.scrollY) + 'px',  // Justo debajo del input
        left: (rect.left + window.scrollX) + 'px',  // Alinear con el borde izquierdo del input
        zIndex: 1000  // Asegurarse de que esté en frente de otros elementos
      };
  
      vm.showKeyboard = true;  // Mostrar el teclado
    }
  };

  // Función para manejar la escritura de teclas
  vm.handleKeyPress = function(key) {
    if (key !== 'close' && key !== 'addPlays') {
      vm.inputValue += key;
    }else if (key === 'close') {
      vm.handleClose();
    }else if(key === 'addPlays'){
      vm.handleClose();
      addPlays();
    }
  };

  // Función para manejar el cierre del teclado
  vm.handleClose = function() {
    vm.showKeyboard = false;  // Ocultar el teclado cuando se haga clic en cerrar
  };

  

  function getAllCashiers(loteria_id) {
    $cashierService.getAllCashiers(loteria_id)
    .then(function(res){
      vm.allCashiers = res;
      vm.loading = false;
      showWallets(res);
    })
  }

  function showWallets(allCashiers){
    var modal = $uibModal.open({
      animation: true,
      component: 'modalAllCashiers',
      windowClass: 'modalAllCashiers',
      backdrop: 'static',
      keyboard: false,
      size: 'xs',
      resolve: {
        allCashiers: function(){
          return allCashiers;
        }
      }
    })
    modal.result.then(function(res){  
      var oldCashier = vm.currentUser.cajero;
      var newFavorite = _.findWhere(res, { checked: true });
      if(newFavorite){
        vm.loadingCajeroWeb = true;
        vm.currentUser.cajero = newFavorite;
        $user.setCurrentUser(vm.currentUser);
        $rootScope.$broadcast('updatedUser', vm.currentUser);
        $state.go("loteries", {"cashier_id": vm.currentUser.cajero.id});
      }
    })
  }

  function showModalPagoMovil(){
    var modal = $uibModal.open({
      animation: true,
      component: 'modalPagoMovil',
      windowClass: 'modalPagoMovil',
      backdrop: 'static',
      keyboard: false,
      size: 'xs',
    })
    modal.result.then(function(res){  
      vm.pagoMovil = res;
      console.log(vm.pagoMovil)
    })
  }

  function addPlays() {
    if (validateAmountMult()) {
      var fail = vm.numberPlays.match(',');

      if (fail != null && fail.index > 0) {
        $window.swal('Aviso', "La separacion de las jugadas debe ser con un punto '.'", 'error');
        return;
      }
      var failLength = checkLength();
      if (!failLength) {
        var plays = _.reject(vm.numberPlays.split('.'), function (element) {
          return element == "";
        });


        makePlays(plays)
      } else {
        $window.swal('Aviso', "Hay una jugada incorrecta, verifique e intente nuevamente", 'error');
        return;
      }
    }
  }

  function checkLength() {
    var i = 0;
    var plays = vm.numberPlays.split('.');
    _.forEach(plays, function (data) {
      if (data.toString().split('').length > 3) {
        i++;
      }
    })
    return i > 0 ? true : false;
  }


  function sale() {
    console.log('comprar')
    if (parseInt(vm.total.monto) < parseInt(vm.config_lot.mmt)) {
      $window.swal('Aviso', "Monto minimo del ticket: " + vm.prettyAmount(vm.config_lot.mmt), 'error');
      return
    }
    var i = 1;
    if (vm.total === undefined) {
      toastr.warning('Debe agregar las jugadas');
      return
    }

    vm.sendData = true;

    vm.plays = angular.copy(
      _.reduce(vm.listCombinationsToPlay, function (memo, data) {
          _.forEach(data.numeros, function(numero){
            memo.push(numero);
          })
        return memo;
      }, [])
    )

    _.forEach(vm.plays, function (draw) {
      draw.i = i;
      i++;
    })

    var data = {
      fec: moment().format('DD/MM/YY'),
      ts: 1,
      correo: vm.currentUser.correo,
      jug: vm.plays,
      compress: false,
      cupon: "",
      usa_cupon: false,
      app: 5,
      jp: "H",
      ani: false,
      tip: "T",
      uti: 0,
      cod: vm.currentUser.id,
      ven: 1,
      ani_tipo: 5,
      producto_id: 'triples',
      beneficiencia: '',
      cda: true,
      loterias: true,
      cajero_id: vm.currentUser.cajero.id,
      cedula: vm.pagoMovil != undefined ? vm.pagoMovil.cedula : '',
      telefono: vm.pagoMovil != undefined ? vm.pagoMovil.telefono : '',
      banco_id: vm.pagoMovil != undefined ? vm.pagoMovil.banco : '',
    }


    if (vm.cupon != null && vm.total.monto > vm.cupon.disponible) {
      $window.swal({
        html: 'Modifique la juagada si desea usar el bono exacto. <br>' +
          '<strong>Monto disponible:</strong>' + vm.cupon.disponible + '<br>',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false,
      }).then(function (result) {
        if (result.value) {
          $loteriesService.sale(data)
          .then(function (res) {
            vm.sendData = false;
            completeSale(res);
          }, function (err) {
            vm.sendData = false;
            $window.swal('Aviso', err.data.message, 'warning');
          })
        } else {
          vm.sendData = false;
          return;
        }
      })
    } else {
      $loteriesService.sale(data)
        .then(function (res) {
          vm.sendData = false;
          completeSale(res);
        }, function (err) {
          vm.sendData = false;
          $window.swal('Aviso', err.data.message, 'warning');
        })
    }
  }

  function completeSale(res) {
    console.log(res, vm.plays)
    switch (res.cmd) {
      case "C11":
        var modal = $uibModal.open({
          animation: true,
          component: 'modalConfirmLoteries',
          backdrop: 'static',
          windowClass: 'modalConfirmLoteries',
          size: 'md',
          resolve: {
            plays: function (){
              return vm.plays;
            },
            playsDenied: function (){
              return res;
            },
            cajero_Web: function(){
              return vm.currentUser.cajero;
            }
          },
        });

        modal.result.then(function (res) {
          vm.aceptPlays(res);
          vm.clearPlays();
        }, function (err) {
          vm.cancelPlay(res);
          vm.clearPlays();
        })
        break;
      case "C12":
        toastr.error('Las Jugadas Seleccionadas no tienen ningun limite', 'Error');
        vm.clearDraws();
        vm.clearPlays();
        break;
      case "C10":
        vm.clearPlays();
        vm.clearDraws();
        $rootScope.$broadcast('updateCajeroUser', res.cajero_web);
        if (res.cupon != null) {
          $rootScope.$broadcast('updateCupon', res.cupon);
        }
        vm.ticket = $sce.trustAsHtml(angular.copy(res.ticket.replace(/\n|\r/g, '<br>')));
        vm.ticket_ws = angular.copy(res.ticket.replace(/\\n/g, '<br>'));
        vm.ticket_print = angular.copy(res.ticket.replace(/\n|\r/g, '\n'));


        $timeout(function () {
          vm.showTicket(vm.ticket, vm.ticket_print, vm.ticket_ws, res.codagen_info, res.nt)
        }, 0)
        if (window.mobileAndTabletcheck()) {
          vm.step1 = true;
          vm.step2 = false;
        }
        _.forEach(vm.signs, function (data) {
          data.checked = false;
        });
        vm.finalNumbersPlays = undefined;
        break;
      default:

    }
  }

  function showTicket(ticket, ticket_print, ticket_ws, codagen_info, tracking_id) {
    var modal = $uibModal.open({
      animation: true,
      component: 'gModalTicket',
      backdrop: 'static',
      windowClass: 'modalTicket',
      size: 'xs',
      resolve: {
        ticket: function () {
          return ticket;
        },
        ticket_print: function () {
          return ticket_print;
        },
        ticket_ws: function () {
          return ticket_ws;
        },
        codagen_info: function(){
          return codagen_info;
        },
        tracking_id: function(){
          return tracking_id;
        }
      },
    })
    modal.result.then(function(res){
      $rootScope.$emit('updateCupon', null)
      if(vm.currentUser.modality == 'cashinCashout'){
        $rootScope.$emit('updateModality',{});
        vm.currentUser.modality = 'autoServicio';
        $user.setCurrentUser(vm.currentUser);
      }
      $state.go('lobby')
    })
  }

  function aceptPlays(data) {
    vm.clearPlays();
    var data = {
      cod: vm.currentUser.id,
      ven: 1,
      ticket: data.nt,
      jp: "H",
      producto_id: 'triples',
      cajero_id: vm.currentUser.cajero.id,
    }
    $loteriesService.confirmSale(data)
      .then(function (res) {
        completeSale(res);
        if (window.mobileAndTabletcheck()) {
          vm.step1 = true;
          vm.step2 = false;
        }
      })
  }

  function cancelPlay(data) {
    var data = {
      cod: vm.currentUser.id,
      ven: 1,
      ticket: data.nt,
      jp: "H"
    }
    $loteriesService.reverTicket(data)
      .then(function (res) {
        // toastr.info('Jugada Devuelta');
        if (window.mobileAndTabletcheck()) {
          vm.step1 = true;
          vm.step2 = false;
        }
      })
  }

  function getNumeros(draw, plays) {
    var numeros = _.reduce(vm.signs, function (memo, data) {
      if (data.checked) {
        _.forEach(plays, function (play) {
          memo.push({
            i: null,
            n: play,
            s: data.code,
            c: draw.id,
            m: vm.amount
          })
        })
      }
      return memo;
    }, []);
    return numeros;
  }

  function getNumerosTwo(draw, plays) {
    var numeros = _.reduce(plays, function (memo, data) {
      memo.push({
        i: null,
        n: data,
        s: '',
        c: draw.id,
        m: vm.amount
      })
      return memo;
    }, []);

    return numeros;
  }

  function checkDrawSelected() {
    var i = 0;
    var openModalSing = 0;
    _.forEach(vm.drawsLoteries, function (lotery) {
      _.forEach(lotery.sorteos, function (draw) {
        if (draw.selected) {
          i++
        };
      })
    });
    return i > 0 ? true : false;
  }

  function zodiacalSelected() {
    var i = 0;
    _.forEach(vm.drawsLoteries, function (lotery) {
      _.forEach(lotery.sorteos, function (draw) {
        if (draw.tipo == "Z" && draw.selected) {
          i++
        }
      });
    });

    return i > 0 ? true : false;
  }

  function openModalSigns() {
    var openedModal = 0;
    _.forEach(vm.drawsLoteries, function (lotery) {
      _.forEach(lotery.sorteos, function (draw) {
        if (draw.tipo == "Z" && openedModal == 0 && draw.selected) {
          openedModal++
          var modal = $uibModal.open({
            animation: true,
            component: 'modalSigns',
            backdrop: 'static',
            windowClass: 'modalSigns',
            size: 'xs',
            resolve: {
              signs: function () {
                return vm.signs;
              }
            }
          });

          modal.result.then(function (res) {
            makePlaysWithZodiacal();

            var el = angular.element(document.querySelector('#buyButton'));
            if(el.length > 0){
              console.log(el[0].focus());
              el[0].focus();
              el[0].focus();
            }
            console.log(el[0])
          })
        }
      })
    });
  }

  function makePlaysWithZodiacal() {
    _.forEach(vm.drawsLoteries, function (lotery) {
      _.forEach(lotery.sorteos, function (draw) {
        if (draw.selected) {
          var plays = vm.finalNumbersPlays != undefined ? vm.finalNumbersPlays : _.reject(vm.numberPlays.split('.'), function (el) { return el == "" });
          var jugadas = { numeros: [], sorteo: null, sorteo_id: null, orden: draw.orden };

          if (draw.tipo != "Z") {
            jugadas.sorteo = lotery.nombre + ' ' + draw.name + ' ' + draw.prettyHour;
            jugadas.sorteo_id = draw.id;
            jugadas.numeros = jugadas.numeros.concat(getNumerosTwo(draw, plays))
          } else {
            jugadas.sorteo = lotery.nombre + ' ' + draw.name + ' ' + draw.prettyHour;
            jugadas.sorteo_id = draw.id;
            jugadas.numeros = jugadas.numeros.concat(getNumeros(draw, plays))
          }
          vm.listCombinationsToPlay.push(jugadas);
          if ($window.mobileAndTabletcheck()) {
            vm.step1 = false;
            vm.step2 = true;
          }
        }
      })
    });
    calculateTotal();
  }

  function deleteNumber(draw, index) {
    draw.numeros.splice(index, 1);
    if (draw.numeros.length == 0) {
      vm.listCombinationsToPlay.splice(draw, 1);
    }
    calculateTotal();
  }

  function deleteDraw(draw) {
    vm.listCombinationsToPlay.splice(draw, 1);
    calculateTotal();
  }

  function checkPlays() {
    return vm.numberPlays != undefined ? true : false;
  }

  function clearPlays() {
    vm.listCombinationsToPlay = [];
    calculateTotal();
    if ($window.mobileAndTabletcheck()) {
      vm.step1 = true;
      vm.step2 = false;
    }
    _.forEach(vm.signs, function (signo) {
      signo.checked = false;
    })
    _.forEach(vm.drawsLoteries, function (loteria) {
      _.forEach(loteria.sorteos, function (sor) {
        sor.selected = false;
      })
    })
    vm.amount = undefined;
    vm.numberPlays = undefined;
    vm.finalNumbersPlays = undefined;
  }
  function clearDraws() {
    _.forEach(vm.drawsZoo, function (res) {
      res.checked = false;
    })
  }

  function nextStep() {
    if (vm.step1) {
      vm.step1 = false;
      vm.addPlays();
      $timeout(function () {
        vm.step2 = true;
      }, 0)
      return
    }
  }

  function selectAllDraws(lotery) {
    _.forEach(lotery.sorteos, function (data) {
      if (!data.disabled) {
        data.selected = !data.selected;
      }
    })
  }

  //Custom plays

  function calculateTotal() {
    vm.total = _.reduce(vm.listCombinationsToPlay, function (memo, data) {
      _.forEach(data.numeros, function (numero) {
        memo['monto'] += parseFloat(numero.m);
      })
      memo['jugadas'] += data.numeros.length;
      return memo;
    }, { monto: 0, jugadas: 0 });
  }

  function checkCoupons() {
    $cashierService.getCoupon()
      .then(function (res) {
        vm.cupon = res;
        if (checkLoteriesDraws()) {
          // if(parseFloat(vm.currentUser.cajero.saldo_actual) == 0 && vm.cupon == null){
          //   vm.notBalance = true;
          //   vm.errorMessage = 'Recarge su saldo para continuar jugando';
          // }
        } else {
          // vm.notBalance = true;
          // vm.errorMessage = 'Taquilla cerrada';
        }
      })
  }

  function getSpecialPlay(type) {
    var typePlay = type;
    //Permuta: 1, Serie: 2, Corrida: 3
    var message;
    switch (typePlay) {
      case 1:
        message = 'Indique el triple a permutear ej: 420';
        resource = "$miscelaneos.getNumbersPermuted({plays: play})"
        break;
      case 2:
        message = 'Indique el terminal para la serie ej: 20';
        resource = "$miscelaneos.getSerieNumbers({plays: play})"
        break;
      case 3:
        message = 'Indique los 2 triples para la corrida eje: 420.430';
        resource = "$miscelaneos.getCorridaNumbers({plays: play})"
        break;
      default:
        break;
    }

    $window.swal({
      title: message,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        required: 'true',
        'ng-focus': "$ctrl.showKeyboardAtPosition($event)"
      },
      showCancelButton: true,
      confirmButtonText: 'Agregar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      inputValidator: function (result) {
        return !result && 'Debe indicar un triple'
      },
      preConfirm: function (play) {
        var response = validSpecialPlay(play, typePlay);
        console.log(response)
        if(!response.valid){
          $window.swal.showValidationError(response.message);
          return 
        }
        return eval(resource)
          .then(function (response) {
            return response;
          }, function (err) {
            $window.swal.showValidationError(err.data.message);
          })
      },
      allowOutsideClick: function () {
        !$window.swal.isLoading()
      }
    }).then(function (result) {
      if (result.value) {
        vm.numberPlays = '';
        switch (typePlay) {
          case 1:
            vm.finalNumbersPlays = result.value.permutation;
            makePlays(result.value.permutation);
            break;
          case 2:
            vm.finalNumbersPlays = result.value.series;
            makePlays(result.value.series);
            break;
          case 3:
            vm.finalNumbersPlays = result.value.corridas;
            makePlays(result.value.corridas);
            break;
          default:
            break;
        }
        if (window.mobileAndTabletcheck()) {
          nextStep();
        }
      }
    })
  }

  function getNumbersPermuted() {
    if (validateAmountMult()) {
      if ($window.mobileAndTabletcheck()) {
        if (vm.checkDrawSelected()) {
          if (zodiacalSelected()) {
            getSpecialPlay(1);
          } else {
            getSpecialPlay(1);
          }
        } else {
          $window.swal('Seleccione un sorteo');
        }
      } else {
        getSpecialPlay(1);
      }
    }
  }

  function getSerieNumbers() {
    if (validateAmountMult()) {
      if ($window.mobileAndTabletcheck()) {
        if (vm.checkDrawSelected()) {
          if (zodiacalSelected()) {
            getSpecialPlay(2);
          } else {
            getSpecialPlay(2);
          }
        } else {
          $window.swal('Seleccione un sorteo');
        }
      } else {
        getSpecialPlay(2);
      }
    }
  }

  function getCorridaNumbers() {
    if (validateAmountMult()) {
      if ($window.mobileAndTabletcheck()) {
        if (vm.checkDrawSelected()) {
          if (zodiacalSelected()) {
            getSpecialPlay(3);
          } else {
            getSpecialPlay(3);
          }
        } else {
          $window.swal('Seleccione un sorteo');
        }
      } else {
        getSpecialPlay(3);
      }
    }
  }

  function validSpecialPlay(play, typePlay){
    switch (typePlay) {
      case 1:
        var checkNumber = play.split('').length;
        if (checkNumber > 3 || checkNumber <= 2) {
          return {valid: false, message: 'No es un numero valido debe ser ej: 123'};
        }else{
          return { valid: true }
        }
        break;
      case 2:
        var checkNumber = play.split('').length;
        if (checkNumber > 2 || checkNumber < 2) {
          return {valid: false, message: 'No es un numero valido debe ser ej: 23'};
        }else{
          return { valid: true }
        }
        break;
      case 3:
        if(play.split('.').length % 2 != 0){
          return { valid: false, message: 'No es una corrida correcta, debe tener Inicio.Fin'};
        }else{
          var triple = play.split('.')[0].split('').length;
          var tripleB = play.split('.')[1].split('').length;
          if (triple > 3 || triple <= 2 && tripleB > 3 || tripleB <= 2) {
            return {valid: false, message: 'Deben ser 2 triples validos Ej: 420.520'};
          }else{
            return { valid: true }
          }
        }
        break;
      default:
        return {valid: true}
        break;
    }
  }

  function makePlays(plays) {
    if (vm.amount != undefined) {
      if (checkPlays()) {
        if (vm.checkDrawSelected()) {
          if (zodiacalSelected()) {
            openModalSigns();
          } else {
            var plays = plays;
            _.forEach(vm.drawsLoteries, function (lotery) {
              _.forEach(lotery.sorteos, function (draw) {
                if (draw.selected) {
                  var drawExist = _.findWhere(vm.listCombinationsToPlay, { sorteo_id: draw.id });
                  if (drawExist) {
                    drawExist.numeros = drawExist.numeros.concat(getNumerosTwo(draw, plays));
                  } else {
                    var jugadas = { numeros: [], sorteo: null, sorteo_id: null, orden: draw.orden };
                    jugadas.sorteo = lotery.nombre + ' ' + draw.name + ' ' + draw.prettyHour;
                    jugadas.sorteo_id = draw.id;
                    jugadas.numeros = jugadas.numeros.concat(getNumerosTwo(draw, plays))
                    vm.listCombinationsToPlay.push(jugadas);
                  }
                }
              })
            })
            calculateTotal();
            var el = angular.element(document.querySelector('#buyButton'));
            console.log(el[0])
            if(el.length > 0){
              console.log(el[0].focus());
              el[0].focus();
              el[0].focus();
            }
          }
        } else {
          toastr.warning('Seleccione un sorteo');
        }
      } else {
        toastr.warning('Ingrese una jugada.', 'Por favor espere!');
      }
    } else {
      toastr.warning('Agregue un monto para la jugada.', 'Por favor espere!');

    }
  }

  function checkLoteriesDraws() {
    var i = _.reduce(vm.loteries, function (memo, data) {
      _.forEach(data.sorteos, function () {
        if (!data.disabled) {
          memo = + memo;
        }
      })
    }, 0);

    return i > 0 ? false : true;
  }

  vm.continuePlaying = function () {
    vm.amount = undefined;
    vm.numberPlays = undefined;
    _.forEach(vm.signs, function (signo) {
      signo.checked = false;
    })
    _.forEach(vm.drawsLoteries, function (loteria) {
      _.forEach(loteria.sorteos, function (sor) {
        sor.selected = false;
      })
    })

    if (window.mobileAndTabletcheck()) {
      vm.step2 = false;
      setTimeout(function () {
        vm.step1 = true;
      })
    }
  }

  vm.prettyAmount = function (amount) {
    return accounting.formatMoney(amount, '', ',', '.')
  }

  function validateAmountMult() {
    var isValid = parseInt(vm.amount) % vm.config_lot.mt;
    if (isValid == 0) {
      return true
    } else {
      $window.swal('Aviso', "La jugada debe ser multriplo de " + vm.prettyAmount(vm.config_lot.mt), 'error');
      return
    }
  }

  vm.selectDraw = function(draw){
    if(!draw.disabled){
      draw.selected = !draw.selected;
    }
  }

  hotkeys.add({
    combo: '+',
    description: 'Evento to buy plays',
    callback: function() {
      vm.sale();
    }
  });

  $rootScope.$emit('hideFooter',{})
  $rootScope.$emit('updateState', $state.current.name)
  console.log($state.current.name, '$state.current.name')
}
