(function () {
	'use strict';
	angular
		.module('app')
		.component('numericKeyboard', {
			bindings: {
				showDecimal: '<',
				onKeyPress: '&'
			},
			templateUrl: '/app/directives/numeric-keyboard.html',
			controller: function () {
				this.keys = [1, 2, 3, 4, 5, 6, 7, 8, 9];
				var lastFocusedElement = null;  // Para almacenar el último input enfocado

				// Método para insertar números en el input actualmente enfocado
				this.handleKeyPress = function (key) {
					// Si hay un último elemento enfocado, restaurar el foco
					if (lastFocusedElement) {
						lastFocusedElement.focus();

						// Obtener el valor y la posición del cursor
						var currentValue = lastFocusedElement.value;
						var start = lastFocusedElement.selectionStart;
						var end = lastFocusedElement.selectionEnd;

						// Inserta el número o símbolo en la posición del cursor
						lastFocusedElement.value = currentValue.slice(0, start) + key + currentValue.slice(end);

						// Actualiza la posición del cursor después de insertar
						lastFocusedElement.selectionStart = lastFocusedElement.selectionEnd = start + 1;

						// Disparar un evento de input para que AngularJS detecte el cambio
						angular.element(lastFocusedElement).triggerHandler('input');
					}
				};

				// Método para borrar el último carácter en el input con el foco
				this.handleDelete = function () {
					if (lastFocusedElement) {
						lastFocusedElement.focus();
						var currentValue = lastFocusedElement.value;
						var start = lastFocusedElement.selectionStart;
						var end = lastFocusedElement.selectionEnd;

						// Si hay algo seleccionado, se borra la selección
						if (start !== end) {
							lastFocusedElement.value = currentValue.slice(0, start) + currentValue.slice(end);
						} else if (start > 0) {
							// Si no hay selección, se borra el carácter antes del cursor
							lastFocusedElement.value = currentValue.slice(0, start - 1) + currentValue.slice(end);
							lastFocusedElement.selectionStart = lastFocusedElement.selectionEnd = start - 1;
						}

						angular.element(lastFocusedElement).triggerHandler('input');
					}
				};

				// Método para cerrar el teclado
				this.handleClose = function () {
					if (lastFocusedElement) {
						lastFocusedElement.blur();  // Quitar el foco del último input
						lastFocusedElement = null;  // Restablecer el último input enfocado
						this.onKeyPress({ key: 'close' });
					}
				};

				this.handlAddPlays = function () {
					if (lastFocusedElement) {
						lastFocusedElement.blur();  // Quitar el foco del último input
						lastFocusedElement = null;  // Restablecer el último input enfocado
						this.onKeyPress({ key: 'addPlays' });
					}
				}

				// Evento para detectar cuál es el último input enfocado
				document.addEventListener('focusin', function (event) {
					var target = event.target;
					if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
						lastFocusedElement = target;  // Guardar el último elemento enfocado
					}
				});
			}
		});
})();
