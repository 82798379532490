(function () {
  'use strict';
  angular
    .module('app')
    .component('printView', {
      templateUrl: 'app/components/print-view/printView.html',
      controller: PrintViewController,
    });

  PrintViewController.$inject = ['$window', 'user', '$sce', 'agentsPanelService', '$uibModal', '$state', '$timeout','$rootScope'];

  function PrintViewController($window, $user, $sce, $agentsPanelService, $uibModal, $state, $timeout, $rootScope) {
    var vm = this;

    vm.$onInit = function () {
      vm.loading = true;
      vm.currentUser = $user.getCurrentUser();
      $agentsPanelService.getTicketsPrintView({view: 'print_view'})
      .then(function(res){
        vm.loading = false;
        vm.tickets = [];
        $timeout(function(){
          vm.tickets = res;
        },0)
      })
    }

    vm.showTicket = function(listTicket){
      vm.loading = true;
      $agentsPanelService.findTrackingOnPrintView(listTicket.id, 'print_view')
      .then(function(res){
        vm.loading = false;
        var tracking = res;
        vm.ticket = $sce.trustAsHtml(angular.copy(tracking.ticket.replace(/\n|\r/g, '<br>')));
        // vm.ticket = vm.ticket.replace(/(Serial\/S: )(\d+)/g, '$1######');
        vm.ticket_ws = angular.copy(tracking.ticket.replace(/\\n/g, '<br>'));
        vm.ticket_print = angular.copy(tracking.ticket.replace(/\n|\r/g, '\n'));
        $timeout(function () {
          modalTicket(vm.ticket, vm.ticket_print, vm.ticket_ws, tracking)
        }, 0)
      }, function(err){
        vm.loading = false;
        console.log(err);
      })
    }

    function modalTicket(ticket, ticket_print, ticket_ws, tracking) {
      var modal = $uibModal.open({
        animation: true,
        component: 'gModalTicket',
        backdrop: 'static',
        windowClass: 'modalTicket',
        size: 'xs',
        resolve: {
          ticket: function () {
            return ticket;
          },
          ticket_print: function () {
            return ticket_print;
          },
          ticket_ws: function () {
            return ticket_ws;
          },
          tracking_id: function() {
            return tracking.id
          },
          template: function(){
            return 1;
          }
        },
      })
  
      modal.result.then(function (res) {
        vm.$onInit();
      }, function(err) {
        vm.$onInit();
      })
    }

    $rootScope.$on('updateListTickets', function(ev, data){
      vm.$onInit();
    })
  }

})();
