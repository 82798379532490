(function () {
  'use strict';
  angular
  .module('app')
  .component('checkout', {
    templateUrl: 'app/components/checkout/checkout.html',
    controller: CheckoutController,
  });

  CheckoutController.$inject = ['user','agentsPanelService','cashierService','$sce', '$uibModal','$timeout'];

  function CheckoutController($user, $agentsPanelService, $cashierService, $sce, $uibModal, $timeout) {
    var vm = this;
    vm.currentUser = $user.getCurrentUser();
    vm.permitCuadre = true;
    vm.coins = [{ currency: 'VES',
                  sencillo: 0,
                  gastos: 0,
                  pending_cashout: 0,
                  coins: [
                    {key: '0.5', value: 0},
                    {key: '1', value: 0},
                    {key: '2', value: 0},
                    {key: '5', value: 0},
                    {key: '10', value: 0},
                    {key: '20', value: 0},
                    {key: '50', value: 0},
                    {key: '100', value: 0}
                  ],
                  canales_electronicos: [
                    {
                      canal_electronico_name: 'Pago Movil',
                      canal_electronico_value: 0,
                    },
                    {
                      canal_electronico_name: 'Punto de Venta',
                      canal_electronico_value: 0,
                    }
                  ]
                },{
                  currency: 'USD',
                  sencillo: 0,
                  gastos: 0,
                  pending_cashout: 0,
                  coins: [
                    {key: '1', value: 0},
                    {key: '5', value: 0},
                    {key: '10', value: 0},
                    {key: '20', value: 0},
                    {key: '50', value: 0},
                    {key: '100', value: 0}
                  ],
                  canales_electronicos: [
                    {
                      canal_electronico_name: 'Zelle',
                      canal_electronico_value: 0,
                    }
                  ]
                },{
                  currency: 'COP',
                  sencillo: 0,
                  gastos: 0,
                  pending_cashout: 0,
                  coins: [
                    {key: '2000', value: 0},
                    {key: '5000', value: 0},
                    {key: '10000', value: 0},
                    {key: '20000', value: 0},
                    {key: '50000', value: 0},
                    {key: '100000', value: 0}
                  ],
                  canales_electronicos: [
                    {
                      canal_electronico_name: 'Transferencia',
                      canal_electronico_value: 0,
                    }
                  ]
                  }];

    vm.$onInit = function(){
      vm.loading = true;
      $cashierService.getCuadreTaquilla(moment().format('YYYY-MM-DD'))
      .then(function(cuadre){
        if(cuadre.length > 0) {
          vm.monedas = cuadre;
          vm.permitCuadre = false;
        }else {
          $cashierService.salesByCurrency(moment().format('YYYY-MM-DD'))
          .then(function(salesByCurrency){
            vm.salesByCurrency = salesByCurrency;
            $cashierService.pendingCashout(moment().format('YYYY-MM-DD'))
            .then(function(pendingCashout){
              $cashierService.getAllMonedas()
              .then(function(monedas){
                vm.monedas = _.reduce(monedas, function(memo, data){
                  var venta_total_moneda = _.findWhere(vm.salesByCurrency, { moneda_id: data.id })
                  var pending_cashout_moneda = _.findWhere(pendingCashout, { moneda_id: data.id })
                  memo.push({
                    moneda_id: data.id,
                    currency: data.currency,
                    pending_cashout: pending_cashout_moneda ? pending_cashout_moneda.total : 0,
                    total_venta: venta_total_moneda ? venta_total_moneda.total : 0,
                    total_efectivo: 0,
                    cuadre_date: moment(vm.date).format('YYYY-MM-DD'),
                    checkout: _.findWhere(vm.coins, {currency: data.currency.toUpperCase()}),
                  })
                  return memo;
                },[]);
                console.log(vm.monedas)
              })
            })

          })
        }
        vm.loading = false;
      })

    }

    vm.calculateTotalCoins = function(moneda){
      var total = 0;
      _.each(moneda.checkout.coins, function(coin){
        total += coin.value * parseFloat(coin.key);
      })
      total += moneda.checkout.sencillo;
      moneda.total_efectivo = total;
      vm.calculateTotalElectronic(moneda);
    }

    vm.calculateTotalElectronic = function(moneda) {
      var total = angular.copy(moneda.total_venta);
      total -= moneda.total_efectivo;

      var total_electronic = _.reduce(moneda.checkout.canales_electronicos, function(memo, data){
        return memo + data.canal_electronico_value;
      },0)
      total -= total_electronic;
      
      moneda.total = total;
    }

    vm.save = function(){
      vm.loading = true;
      console.log(vm.monedas)
      $cashierService.cuadreTaquilla({monedas: vm.monedas})
      .then(function(res){
        vm.loading = false;
        vm.ticket = $sce.trustAsHtml(angular.copy(res.ticket_print.replace(/\n|\r/g, '<br>')));
        vm.ticket_ws = angular.copy(res.ticket_print.replace(/\\n/g, '<br>'));
        vm.ticket_print = angular.copy(res.ticket_print.replace(/\n|\r/g, '\n'));
        $timeout(function () {
          vm.showTicket(vm.ticket, vm.ticket_print, vm.ticket_ws, null, null)
        }, 0)
        swal('Exito!',res.message, 'success');
      }, function(err){
        vm.loading = false;
        swal('Error!',err.data.message, 'error');
      })
    }

    vm.consult = function() {
      var date = vm.date
      $cashierService.getCuadreTaquilla(moment(date).format('YYYY-MM-DD'))
      .then(function(cuadre){
        if(cuadre.length > 0) {
          vm.monedas = cuadre;
          vm.permitCuadre = false;
        }else {
          $cashierService.salesByCurrency(moment(date).format('YYYY-MM-DD'))
          .then(function(salesByCurrency){
            vm.salesByCurrency = salesByCurrency;
            $cashierService.getAllMonedas()
            .then(function(monedas){
              vm.monedas = _.reduce(monedas, function(memo, data){
                var venta_total_moneda = _.findWhere(vm.salesByCurrency, { moneda_id: data.id })
                memo.push({
                  moneda_id: data.id,
                  currency: data.currency,
                  total_venta: venta_total_moneda ? venta_total_moneda.total : 0,
                  total_efectivo: 0,
                  total_ingresos: 0,
                  cuadre_date: moment(vm.date).format('YYYY-MM-DD'),
                  checkout: _.findWhere(vm.coins, {currency: data.currency.toUpperCase()}),
                })
                return memo;
              },[]);
              vm.permitCuadre = true;
            })
          })
        }
      })
    }

    vm.today = function() {
      vm.date = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.dt = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      // maxDate: new Date(2020, 5, 22),
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false,
      showButtonBar: false,
    };

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };
    vm.toggleMin();

    vm.open1 = function() {
      vm.popup1.opened = true;
    };

    vm.open2 = function() {
      vm.popup2.opened = true;
    };

    vm.setDate = function(year, month, day) {
      vm.dt = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[0];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup1 = {
      opened: false
    };

    vm.popup2 = {
      opened: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }

    vm.showTicket = function(ticket, ticket_print, ticket_ws, codagen_info, tracking_id) {
      var modal = $uibModal.open({
        animation: true,
        component: 'gModalTicket',
        backdrop: 'static',
        windowClass: 'modalTicket',
        size: 'xs',
        resolve: {
          ticket: function () {
            return ticket;
          },
          ticket_print: function () {
            return ticket_print;
          },
          ticket_ws: function () {
            return ticket_ws;
          },
          codagen_info: function(){
            return codagen_info;
          },
          tracking_id: function(){
            return tracking_id 
          }
        },
      })
    }

    
  }

})();
