(function () {
	'use strict';
	angular
		.module('app')
		.component('cashinCashout', {
			templateUrl: 'app/components/cashinCashout/cashinCashout.html',
			controller: CashinCashoutController,
		});

	CashinCashoutController.$inject = ['$scope', 'user', 'toastr', '$uibModal', 'cashierService'];

	function CashinCashoutController($scope, $user, toastr, $uibModal, cashierService) {
		var vm = this;
		vm.uuid = '';


		vm.$onInit = function () {
			vm.currentUser = $user.getCurrentUser();
			cashierService.indexCajeroWeb()
				.then(function (res) {
					vm.cajeroWebs = res;
				})

			$scope.$watch(function () {
				return vm.uuid;
			}, function (newVal) {
				if (newVal && validateUuid(newVal)) {
					vm.saveForm(); // Llamar automáticamente a la función de guardar
				}
			});

		// 	vm.showModalPrint({
		// 		"id": 238,
		// 		"code": "542f2788-e7c6-4086-acc7-74cdb34bd727",
		// 		"used": false,
		// 		"sent": false,
		// 		"coupon_bank_id": 8,
		// 		"created_at": "2024-10-11T16:17:44.682-04:00",
		// 		"updated_at": "2024-10-11T16:17:44.682-04:00",
		// 		"company_id": 29,
		// 		"hash_key": "d04936f9-2eda-431b-8502-1547ca76a2c2",
		// 		"amount": 10000.0,
		// 		"currency": "COP",
		// 		"moneda_id": 9
		// })
		}

		function validateUuid(uuid) {
			const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
			return uuidRegex.test(uuid);
		}

		vm.saveCashinCashout = function(){
			//mostrar un swal confirm con la informacion de los params
			swal({
				title: 'Comprar Saldo de: '+ prettyAmount(vm.params.amount, vm.cajeroWebSelected.currency),
				showCancelButton: true,
				confirmButtonText: 'Aceptar',
				showLoaderOnConfirm: true,
				preConfirm: function(referencia) {
					return cashierService.createCashinCashout(vm.params)
					.then(function(res){
						vm.showModalPrint(res);
						return res;
					}, function(err){
						swal.showValidationError(err.data.message);
					})
				}
			})
		}

		function prettyAmount(amount, currency){
			return accounting.formatMoney(amount, currency + ' ','2','.',',');
		}

		vm.showModalPrint = function(coupon){
			var modal = $uibModal.open({
				animation: true,
        component: 'modalPrintCashinCashout',
        windowClass: 'modalPrintCashinCashout',
				windowId: 'modalQR',
        keyboard: false,
        size: 'md',
				resolve: {
					cashinCashoutCoupon: function(){
						return coupon;
					}
				}
			})
			modal.opened.then(function() {
				var modalElement = document.querySelector('.modalPrintCashinCashout');
				if (modalElement) {
					modalElement.setAttribute('id', 'modalQR');
				}
			})
			modal.result.then(function(uuid){
				console.log(uuid)
			})
		}


		vm.saveForm = function () {
			vm.loading = true;
			cashierService.consultCashin({code: vm.uuid})
			.then(function(res){
				vm.loading = false;
				vm.codesCoupon = res;
			}, function(err){
				vm.loading = false;
				swal('Warning', err.data.message, 'warning');
			})
		};


		vm.makeCashout = function(){
			if(vm.codesCoupon.amout_cashout){
				var message = '¿Retirar Saldo de: '+ prettyAmount(vm.codesCoupon.amout_cashout, vm.codesCoupon.currency);
			}else{
				var message = '¿Retirar saldo total?';
			}
			swal({
				title: message,
				showCancelButton: true,
				confirmButtonText: 'Aceptar',
				showLoaderOnConfirm: true,
				preConfirm: function() {
					return cashierService.makeCashout(vm.codesCoupon)
					.then(function(res){
						if(res.print){
							vm.showModalPrint(res.coupon);
						}else{
							swal('Success', res.message, 'success');
						}
						vm.codesCoupon = undefined;
						vm.uuid = '';
						return res;
					}, function(err){
						vm.codesCoupon = undefined;
						vm.uuid = '';
						swal.showValidationError(err.data.message);
					})
				}
			})
		}


	}

})();
