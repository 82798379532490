(function () {
	'use strict';
	angular
		.module('app')
		.directive('validUuid', function () {
			const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

			return {
				require: 'ngModel',
				link: function (scope, element, attrs, ngModel) {
					ngModel.$validators.validUuid = function (modelValue) {
						if (ngModel.$isEmpty(modelValue)) {
							return true; // Si está vacío, considerarlo válido (puedes cambiar esto si prefieres)
						}

						return uuidRegex.test(modelValue); // Retorna true si es un UUID válido
					};
				}
			};
		})
})();